// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pc372WaCSM6Z6XknjEXZO._3ZoKcU8lo20RxpGpLq-q2c > input,\n.Pc372WaCSM6Z6XknjEXZO._19W3LjrcGnp0I4Gc12OGGz > input {\n    display: none;\n}\n\nbutton:disabled {\n    cursor: not-allowed;\n}\n\n.Pc372WaCSM6Z6XknjEXZO._2NMX9Rspz9y-fxkvQyjwO8 ._2jWuBnXtKHPf1QbefgfWqL, .Pc372WaCSM6Z6XknjEXZO:disabled ._2jWuBnXtKHPf1QbefgfWqL {\n    cursor: not-allowed;\n}", "",{"version":3,"sources":["webpack://./../design-system/lib/button/DsButton.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".btn.btn-toggle-primary > input,\n.btn.btn-toggle-secondary > input {\n    display: none;\n}\n\nbutton:disabled {\n    cursor: not-allowed;\n}\n\n.btn.disabled .icon, .btn:disabled .icon {\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "Pc372WaCSM6Z6XknjEXZO",
	"btn-toggle-primary": "_3ZoKcU8lo20RxpGpLq-q2c",
	"btn-toggle-secondary": "_19W3LjrcGnp0I4Gc12OGGz",
	"disabled": "_2NMX9Rspz9y-fxkvQyjwO8",
	"icon": "_2jWuBnXtKHPf1QbefgfWqL"
};
export default ___CSS_LOADER_EXPORT___;
