// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Q9zdzwJiIazvMHBCS8RrU {\n    max-width: 200px;\n    padding: 1.25rem 1rem;\n    color: black !important;\n    background-color: white !important;\n    border: gray solid 1px !important;\n    display: inline-table;\n    text-align: start;\n\n}\n\n._2nT1D9mqodFyg-hzXHq1m {\n    margin-bottom: 15px;\n}\n\n.zIUNdJS1VYkcwOA-4aupF ._1VK-z46qkEHtPHf5-1uBob > ._2KkQjXaJnaU-mYeLjeZFbm {\n    cursor: pointer;\n}\n\n", "",{"version":3,"sources":["webpack://./../design-system/lib/badge/DsBadge.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,uBAAuB;IACvB,kCAAkC;IAClC,iCAAiC;IACjC,qBAAqB;IACrB,iBAAiB;;AAErB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".tooltip-inner {\n    max-width: 200px;\n    padding: 1.25rem 1rem;\n    color: black !important;\n    background-color: white !important;\n    border: gray solid 1px !important;\n    display: inline-table;\n    text-align: start;\n\n}\n\n.badge-tooltip-contents {\n    margin-bottom: 15px;\n}\n\n.badge .close-button > .icon {\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-inner": "_3Q9zdzwJiIazvMHBCS8RrU",
	"badge-tooltip-contents": "_2nT1D9mqodFyg-hzXHq1m",
	"badge": "zIUNdJS1VYkcwOA-4aupF",
	"close-button": "_1VK-z46qkEHtPHf5-1uBob",
	"icon": "_2KkQjXaJnaU-mYeLjeZFbm"
};
export default ___CSS_LOADER_EXPORT___;
