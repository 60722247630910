// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3s1xYX9qCFlVZ32xLCiKV ._2cURFWljPYOzMKYuLebRbQ {\n  position: absolute;\n  width: 50vw;\n  height: 24px;\n}\n\n._3s1xYX9qCFlVZ32xLCiKV ._2cURFWljPYOzMKYuLebRbQ._3j882iEl_Ie8_r5Mm2eV7z {\n  left: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #000000 calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n._3s1xYX9qCFlVZ32xLCiKV ._2cURFWljPYOzMKYuLebRbQ._1Qe8Kv4-VYnjuZjcBPKAip {\n  right: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #6e777a calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n._3s1xYX9qCFlVZ32xLCiKV ._2oWBEChO-g67xW4aRmKUxm ._33XkBzktbLeLOCeLH7Z-z4._1SI46XXEVAj0adLhN03WCX ._3weyslwgpXz1rEz2uiEiRT,\n._3s1xYX9qCFlVZ32xLCiKV ._2oWBEChO-g67xW4aRmKUxm ._1Lzm6rpD-gaoQ6Saj3AvGM {\n  background-color: white;\n}\n", "",{"version":3,"sources":["webpack://./../design-system/lib/stepper/DsStepper.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,OAAO;EACP;;;;;GAKC;AACH;;AAEA;EACE,QAAQ;EACR;;;;;GAKC;AACH;;AAEA;;EAEE,uBAAuB;AACzB","sourcesContent":[".ds-stepper .track {\n  position: absolute;\n  width: 50vw;\n  height: 24px;\n}\n\n.ds-stepper .track.track--left {\n  left: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #000000 calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n.ds-stepper .track.track--right {\n  right: 0;\n  background: linear-gradient(\n    180deg,\n    rgba(0, 0, 0, 0) calc(50% - 1px),\n    #6e777a calc(50%),\n    rgba(0, 0, 0, 0) calc(50% + 1px)\n  );\n}\n\n.ds-stepper .stepper .stepper-step.current .stepper-indicator,\n.ds-stepper .stepper .stepper-step-inner {\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ds-stepper": "_3s1xYX9qCFlVZ32xLCiKV",
	"track": "_2cURFWljPYOzMKYuLebRbQ",
	"track--left": "_3j882iEl_Ie8_r5Mm2eV7z",
	"track--right": "_1Qe8Kv4-VYnjuZjcBPKAip",
	"stepper": "_2oWBEChO-g67xW4aRmKUxm",
	"stepper-step": "_33XkBzktbLeLOCeLH7Z-z4",
	"current": "_1SI46XXEVAj0adLhN03WCX",
	"stepper-indicator": "_3weyslwgpXz1rEz2uiEiRT",
	"stepper-step-inner": "_1Lzm6rpD-gaoQ6Saj3AvGM"
};
export default ___CSS_LOADER_EXPORT___;
