// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#_1IbUPfmhOu7zm2T3xw_uHD > li#_1IDsuWipqk1pLQfO6GGtlc {\n  margin-right: 0 !important;\n}", "",{"version":3,"sources":["webpack://./../design-system/lib/pagination/DsPagination.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":["#paginationContainer > li#nextPage {\n  margin-right: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": "_1IbUPfmhOu7zm2T3xw_uHD",
	"nextPage": "_1IDsuWipqk1pLQfO6GGtlc"
};
export default ___CSS_LOADER_EXPORT___;
