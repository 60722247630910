// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ysImMAaS2v_1zrDWQBmfV:hover {\n    color: #000000 !important;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./../design-system/lib/contextual-help/DsContextualHelp.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".contextual-help:hover {\n    color: #000000 !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextual-help": "ysImMAaS2v_1zrDWQBmfV"
};
export default ___CSS_LOADER_EXPORT___;
